<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <base-table ref="table" :columns="columns" :service="'card'">
            <template #header>
              <div class="row">
                <div class="col-3">
                  <input
                    v-model="searchParams.search"
                    type="text"
                    class="form form-control"
                    placeholder="Search..."
                  />
                </div>
                <div class="col-2">
                  <select
                    v-model="searchParams.status"
                    class="form form-control"
                    placeholder="Search..."
                  >
                    <option
                      v-for="(option, key) in statusOptions"
                      :key="key"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="col-auto">
                  <button class="btn btn-primary" @click="$refs.table.reload()">
                    Filter
                  </button>
                </div>
                <div class="col"></div>
                <div class="col-3">
                  <input
                    v-model="searchParams.search"
                    type="text"
                    class="form form-control"
                    placeholder="Masukan NRP/NIP"
                  />
                </div>
                <div class="col-auto">
                  <button class="btn btn-danger" @click="$refs.table.reload()">
                    Non-Aktifkan KTA
                  </button>
                </div>
              </div>
            </template>
            <template #action-button="{ data }">
              <button
                class="btn btn-secondary btn-sm"
                @click="handleActionButton('detail', data.value)"
              >
                Detail
              </button>
            </template>
          </base-table>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { toRaw } from "vue";
import BaseTable from "../../components/BaseTable.vue";
// import Service from "../../services/base.service";
import ApprovalSevice from "../../services/approval.service";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      searchParams: {
        search: "",
        status: null,
      },
      statusOptions: [
        { label: "Semua", value: null },
        { label: "Diblokir", value: "DIBLOKIR" },
        { label: "Hilang", value: "HILANG" },
        { label: "Rusak", value: "RUSAK" },
      ],
      columns: [
        {
          field: "card_application.card_type.name",
          title: "Jenis KTA",
          sort: false,
        },
        { field: "card_application.kta_number", title: "No. KTA", sort: false },
        {
          field: "card_application.person.nama_lengkap",
          title: "Nama",
          sort: false,
        },
        {
          field: "card_application.person.dimension.name",
          title: "Matra",
          sort: false,
        },
        {
          field: "card_application.person.rank.name",
          title: "Pangkat/Korps",
          sort: false,
        },
        {
          field: "card_application.person.nip_nrp",
          title: "NRP/NBI/NIP",
          sort: false,
        },
        // {
        //   field: "card_application.person.position.name",
        //   title: "Jabatan",
        //   sort: false,
        // },
        {
          field: "card_application.person.unity.name",
          title: "Kesatuan",
          sort: false,
        },
        { field: "updatedAt", title: "Tanggal Update" },
        { field: "status", title: "Status" },
        { field: "actions", title: "Actions", sort: false, search: false },
      ],
    };
  },
  methods: {
    async approveAction(id) {
      try {
        const request = await ApprovalSevice.approveSubmission({
          cardApplicationId: id,
          status: "DISETUJUI",
        });
        this.$refs.table.reload();
        this.$swal.fire({
          title: "Success!",
          text: "Data berhasil disetujui.",
          icon: "success",
        });
        console.log(request);
      } catch (error) {
        console.log(error);
      }
    },
    confirmApproval(id) {
      this.$swal
        .fire({
          title: "Konfirmasi!",
          text: "Apakah anda yakin akan menyetujui pengajuan ini!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.approveAction(id);
          }
        });
    },
    handleActionButton(type, data) {
      const { id } = toRaw(data);
      switch (type) {
        case "approve":
          break;
        case "ttd":
          break;
        default:
          this.$router.push({ name: "kta-detail", params: { id } });
          break;
      }
    },
  },
};
</script>
